@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    @apply min-h-screen;
  }

  body {
    @apply min-h-screen;
  }

  * {
    @apply box-border;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: transparent #851F1F;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: #851F1F;
  transition: opacity 0.3s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #6E1818;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}