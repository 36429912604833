.App {
  font-family: 'Helvetica', Helvetica, monospace;
  text-align: center;
  height: 100%;
  min-height: 100vh;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}